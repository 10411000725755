<template>
	<div class="main-contain">
		<div class="main-1-wapper1">
			<div class="main-1-wapper2">
				<div class="main-1-main">
					<h1>红外热成像技术应用和模式创新引领者</h1>
					<div class="phone">
						<p>
							<i class="el-icon-phone-outline"></i>
							全国统一客服热线
						</p>
						<p>400-083-0817</p>
					</div>
          <div class="net—view" @click="linkTo">
            <p>
              <i class="el-icon-s-promotion"></i>
              公司官网
            </p>
          </div>
					<div class="icon">
						<div class="icon1" style="color: #38c695;">
							<div class="icon-icon">
								<span class="iconfont icon-meijin"></span>
							</div>
							<span>20年专注医用红外研发及销售</span>
						</div>
						<div class="icon2" style="color: #b27cf5;">
							<div class="icon-icon">
								<span class="iconfont icon-zhuanli"></span>
							</div>
							<span>拥有多项红外领域技术专利</span>
						</div>
						<div class="icon3" style="color: #feb960;">
							<div class="icon-icon">
								<span class="iconfont icon-xiaolian"></span>
							</div>
							<span>服务全国上千家医疗机构 </span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-2-wapper1">
			<div class="main-2-wapper2">
				<div class="img-wapper">
					<img :src='require("@/assets/picture/公司2.png")' class="attachment-full size-full" alt=""
					 loading="lazy">
				</div>
				<div class="card-wapper">
					<div class="card">
						<div class="card-content">
							<h2>关于远舟集团</h2>
							<div>
								远舟集团是一家从2000年就开始从事医用红外影像技术专业研发、生产和销售的高科技集团化企业，拥有杭州远舟医疗科技有限公司，重庆远舟医疗科技有限公司，重庆飞洲光电技术研究院等多家经营实体。集团拥有70多项自主知识产权，是国家高新技术企业，浙江省健康产业优秀企业，杭州市雏鹰计划企业。
							</div>
							<div>
								远舟集团是国内医用红外检测应用技术的创新引领者，集团与以国家超算中心牵头的全国十多所重点科研院校和行业专业协会及众多医学专家建立了良好的战略合作伙伴关系，强强联手，共同搭建了产学研及临床相融合的研究平台，在行业内首创研发出医用红外AI检测平台，并开创了大健康产业智慧经营创新模式的先河。
							</div>
							<div>
								远舟集团认真贯彻和践行“健康中国2030规划纲要“，以核心技术和智慧经营模式构建医养大健康产业互联生态，让红外的健康幸福之光普照中国大地。
							</div>
						</div>
						<span style="background-color: #fc5f45;"></span>
						<span style="background-color: #38c695;left: 25%;"></span>
						<span style="background-color: #b27cf5;left: 50%;"></span>
						<span style="background-color: #feb960;left: 75%;"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="main-3-wapper1">
			<div class="title">
				<h2>核心优势</h2>
				<p>远舟集团在医用红外热成像领域深耕20多年，积累百万级红外影像数据，利用AI及大数据进行行业模式重构和技术创新</p>
			</div>
			<div class="main-3-content">
				<div class="card1" style="color: #FC5F45;">
					<i class="iconfont icon-idcard"></i>
					<p>20+年深耕洞悉医疗健康行业发展</p>
					<span style="background-color: #FC5F45;"></span>
				</div>
				<div class="card2" style="color: #38C695;">
					<i class="iconfont icon-fa-map"></i>
					<p>2000+医疗机构百万级数据积累</p>
					<span style="background-color: #38C695;"></span>
				</div>
				<div class="card3" style="margin-right: 0;color: #b27cf5;">
					<i class="iconfont icon-braille"></i>
					<p>AI+大数据技术模式创新实践</p>
					<span style="background-color: #b27cf5;"></span>
				</div>
			</div>
		</div>
		<div class="main-4-wapper1">
			<div class="title">
				<h2>主要产品</h2>
				<p>提供医用级红外热成像硬件产品、面向专业医疗机构、个人&家庭提供全方位的医疗和健康领域的赋能解决方案</p>
			</div>
			<div class="content">
				<div class="equas">
					<div class="equa" v-for="item,index in datas" :key="index">
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/"+item.pictures)'/>
				
						<div class="name">
							{{item.name}}
						</div>
						<div class="text">
							<h5>产品特点:</h5>
							<p v-for="t,i in item.tedian">
								{{t}}
							</p>
							<h5>应用范围:</h5>
							<p v-for="w,j in item.fanwei">
								{{w}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-5-wapper1">
			<div class="title">
				<h2>核心团队</h2>
				<p>核心团队均由各自领域的极富专业背景、能力极强且有长期从业经验的资深人士和专家学者担纲</p>
			</div>
			<div class="main-5-main">
				<people v-for="item,index in teams" :key="index" :item="item"></people>
			</div>
		</div>
		<div class="main-6-wapper1">
			<div class="main-6-wapper2">
				<div class="title">
					<h2>蓝谷智库</h2>
					<p>蓝谷智库汇集国内计算机、医疗等行业顶级专家和行业领袖，为远舟建设进行顶层设计，为远舟发展保驾护航。</p>
				</div>
				<div class="main-6-main">
					<div class="langu">
						<img :src='require("@/assets/picture/蓝谷智库.png")' />
					</div>
					<div class="wangqi">
						<img :src='require("@/assets/picture/国医大师王琦.png")' class="topwanqi" />
						<div>
							成立“远舟医疗王琦九种体质工作站”
							，围绕实现红外图谱和九种体质结合临床应用开展工作，课题研究成果将大量推广到我国的基层医疗（社区医疗），作为老百姓认知自我的基础，从而进行更精准地健康及慢病管理，使得全民健康的步伐往前跨了一大步，整个全民健康的工作也将得到扎实的解决。
						</div>
						<img :src='require("@/assets/picture/王琦.png")' class="buwangqi" />
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</div>
		<div class="main-8-wapper1">
			<div class="title">
				<h2>主要合作伙伴与客户</h2>
				<p>与以国家超算中心为龙头的全国十数所重点科研院校和行业协会及众多医学专家建立了良好的战略合作伙伴关系，</p>
				<p>为全国2000+医疗机构、10000+体检和社康机构提供MTI产品及服务</p>
			</div>
			<div class="main-8-main">
				<div>
					<h5>主要合作伙伴</h5>
					<div class="img">
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-京东健康.png")'/>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-企鹅杏仁.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-小米健康.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/15-海康威视.jpg")' />
					</div>
					<div class="img">
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-中辕中医.png")'/>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-高德红外.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-美胸汇.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-贵州农社.png")' />
					</div>
				</div>
				<div>
					<h5>主要使用客户</h5>
					<div class="img imgrad">
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-湘雅三医院.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-广州医科大三.png")'/>
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-空中总医院.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-南京总医院.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-解放军301.png")' />
					</div>
					<div class="img imgrad">
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-上海仁济.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-上海中医院.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-广东第二中医院.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-重庆医科大附一.png")' />
						<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/伙伴客户-新疆人民医院.png")' />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const myEquiData = [
		{
			name: 'MTI-EX PRO-A/B 专业型',
			pictures: 'product/MTI-EX-PRO-AB专业型.jpg',
			tedian: [
				'热平衡全封闭检查舱',
				'检查精度高',
			],
			fanwei: [
				'医疗科研机构、大型三甲医院'
			]
		},
		{
			name: 'MTI-Standard-A/B 通用型',
			pictures: 'product/MTI-Standard-B-通用型.jpg',
			tedian: [
				'半仓体设计',
				'提高成像质量和稳定性',
			],
			fanwei: [
				'二级以上医院，体检中心'
			]
		},
		{
			name: 'MTI-Economy-A/B 普及型',
			pictures: 'product/MTI-Economy-B-普及B型.jpg',
			tedian: [
				'中西医融合',
				'人工智能解读热像图',
			],
			fanwei: [
				'基层及社区医疗',
				'早期健康筛查的最佳工具'
			]
		},
		{
			name: 'MTI-X7 Pro-A/B 疼痛型',
			pictures: 'product/MTI-X7-Pro-B疼痛型.jpg',
			tedian: [
				'高续航锂电',
				'可移动三维多角度探测器',
			],
			fanwei: [
				'各级医院疼痛科、康复理疗科'
			]
		},
		{
			name: 'MTI-Economy-C 便携式',
			pictures: 'product/MTI-Economy-C-便携式.jpg',
			tedian: [
				'既可用做全身健康检测',
				'又能在突发卫生事件中做无接触式应急体温检测',
			],
			fanwei: [
				'基层医疗/乡村健康普查',
				'防疫期间出入口体温检测'
			]
		},
		{
			name: 'MTI-Economy-D 小胖哥',
			pictures: 'product/MTI-Economy-D-小胖哥.jpg',
			tedian: [
				'设计感强，环境匹配度高',
				'提升受检者体验',
			],
			fanwei: [
				'企业员工健康检测'
			]
		},
		{
			name: 'MTI-Economy-X 小管家',
			pictures: 'product/小管家（官网）.jpg',
			tedian: [
				'性价比高，便捷可移动'
			],
			fanwei: [
				'基层医疗机构，如卫生院、诊所等机构'
			]
		},
	]
	
	const teams = [{
			img: '团队成员-董.jpg',
			name: '董海舟',
			desc: '远舟集团创始人，董事长'
		},
		{
			img: '团队成员-曹东兴.jpg',
			name: '曹东兴',
			desc: '集团副总裁-主管智能硬件研发'
		},
		{
			img: '团队成员-郭迪伟.jpg',
			name: '郭迪伟',
			desc: '集团副总裁-主管生产制造'
		},
		{
			img: '团队成员-李宝文.jpg',
			name: '李宝文',
			desc: '集团副总裁-主管市场销售'
		},
	]

	import People from '@/components/people/index'
	import elementResizeDetectorMaker from 'element-resize-detector'

	export default {
		name: 'AppMain',
		components: {
			People,
		},
		mounted() {
			this.datas = myEquiData
			this.teams = teams
			const erdm = elementResizeDetectorMaker()
			erdm.listenTo(document.querySelector('.card'), element => {
				const newval = element.offsetHeight
				const cardwapperheight = document.querySelector('.card-wapper').offsetHeight
				if (newval !== cardwapperheight) {
					document.querySelector('.card-wapper').style.height = newval + 'px'
				}
			})
		},
		data() {
			return {
				datas: [],
				teams: []
			}
		},

    methods: {
      linkTo(){
        location.href = 'http://chnyz.net'
      }
    }
	}
</script>

<style lang="scss">
	@import "./index.scss";
</style>
